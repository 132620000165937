export default {
    props: {},
    sx: theme => ({
        "& .floatingContainer": {
            position: "fixed",
            bottom: 54,
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            background: "#fff",
            padding: theme.spacing(2.6, 0),
            zIndex: 1000,
            [theme.breakpoints.up("sm")]: {
                bottom: 64,
            },
            [theme.breakpoints.up("md")]: {
                position: "static",
                flexDirection: "column",
                marginBottom: theme.spacing(6),
                padding: 0,
            },
            [theme.breakpoints.up("lg")]: {
                marginBottom: theme.spacing(7),
            },
            "& .btn": {
                margin: theme.spacing(0, 2.125),
                [theme.breakpoints.down("sm")]: {
                    padding: theme.spacing(1, 2),
                    margin: theme.spacing(0, 1.125),
                },
                [theme.breakpoints.up("md")]: {
                    margin: theme.spacing(0, 0, 2.5, 0),
                    "&:last-child": {
                        marginBottom: 0,
                    },
                },
            },
            "& .pof-btn": {
                "textTransform": "unset"
            },
            "& .sm-visible": {
                display: "inline-block",
                [theme.breakpoints.up("sm")]: {
                    display: "none",
                },
            },
            "& .md-visible": {
                display: "none",
                [theme.breakpoints.up("sm")]: {
                    display: "inline-block",
                },
                [theme.breakpoints.up("md")]: {
                    display: "none",
                },
            },
            "& .lg-visible": {
                display: "none",
                [theme.breakpoints.up("md")]: {
                    display: "inline-block",
                },
            },
        },
        "&.floatingContainer-dense": {
            "& .floatingContainer": {
                "& .btn": {
                    [theme.breakpoints.down("sm")]: {
                        fontSize: theme.bodySmall,
                        padding: theme.spacing(1),
                        margin: theme.spacing(0, 0.5),
                    },
                    "@media (max-width: 320px)": {
                        fontSize: theme.bodyXSmall,
                    },
                    [theme.breakpoints.up("md")]: {
                        margin: theme.spacing(0, 0, 2.5, 0),
                        "&:last-child": {
                            marginBottom: 0,
                            color: "sssss",
                        },
                    },
                },
                "&.has-offer-btn": {
                    "& .offerBtn": {
                        maxWidth: "100%",
                        width: "100%",
                        [theme.breakpoints.up("md")]: {
                            marginBottom: "20px",
                        },
                    },
                    [theme.breakpoints.down("md")]: {
                        "& .contactBtn": {
                            display: "none",
                        },
                    },
                },
            },
        },
    }),
}
